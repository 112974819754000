thead td {
  @apply font-semibold text-gray-500;
}

table tbody td {
  @apply text-gray-600;
}

table tbody tr:last-child td {
  @apply shadow-none;
}
